import React, { Fragment } from "react";
import { Link } from "gatsby";

import Seo from "../blocks/seo/Seo";

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import "../assets/sass/style.scss";

import PageTitleGeneric from "../blocks/page-title/PageTitleGeneric";
import BlogPostsData from "../data/blog/blogPostsItems";
import LoadMoreButton from "../components/loadmore/LoadMore";
import PlaceholderImage from "../assets/img/placeholder/527x500.jpg";

const Blog = () => {
  return (
    <Fragment>
      <Seo
        title="Blog"
        description="blog description"
        keywords="blog keywords"
        bodyAttributes={{
          class: "blog bg-fixed bg-line",
        }}
      />

      <Header />

      <main id="main" className="site-main">
        <PageTitleGeneric title="Blog" />

        <section id="page-content" className="spacer p-top-xl">
          <div id="blog">
            <div className="wrapper">
              <div className="row gutter-width-lg">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="row gutter-width-lg with-pb-lg">
                    {BlogPostsData &&
                      BlogPostsData.map((item, key) => {
                        return (
                          <div
                            key={key}
                            className="col-xl-4 col-lg-4 col-md-4 col-sm-4"
                          >
                            <div className="card card-post">
                              <div className="card-top position-relative">
                                <Link to="/blog-post">
                                  <div className="img object-fit overflow-hidden">
                                    <div className="object-fit-cover transform-scale-h">
                                      <img
                                        className="card-top-img"
                                        src={PlaceholderImage}
                                        alt={item.imgAlt}
                                      />
                                    </div>
                                  </div>
                                </Link>

                                <div className="card-category">
                                  <Link
                                    className="btn btn-sm btn-light transform-scale-h border-0"
                                    to="/blog-post"
                                  >
                                    {item.categoryTitle}
                                  </Link>
                                </div>
                              </div>

                              <div className="card-body border">
                                <h5 className="card-title">
                                  <Link to="/blog-post">{item.title}</Link>
                                </h5>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <LoadMoreButton />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Blog;
